<template>
  <div style="background-color: #EBEED7;">
   <section>
      <article
        style="padding-top: 160px;">
      
        <div class="container" style="margin: 50px auto; text-align: left">
          <div class="dining_introduction">
            <p class="locus_word_style">
                    <small>KINAWATAKA WOMEN INITIATIVES</small>
                  </p>
            <h2 style="font-weight: 600; font-size: 2em">
              About Us
            </h2>
            <p style="font-weight: 600; font-size: 1.2em">
              Women Recycling straws.
            </p>
            <p>
              Kinawataka Women Initiatives(KIWOI) was formed in 1998 to empower marginalized 
              women in Kinawataka slum of Kampala through environmentally conscious economic 
              projects. After retiring from the post of senior note examiner at the Bank of Uganda, 
              Benedicta Nanyonga found herself without a steady source of income and a means 
              to provide for her basic needs. Motivated by the prospects of raising herself out 
              of poverty, she began exploring ways to repurpose the plastic waste she found 
              collecting in her slum community of Kinawataka. Applying traditional weaving skills 
              that she had acquired as a child, Ms. Nanyonga discovered that she could create and 
              sell unique bags, mats and accessories using discarded plastic straws. By engaging 
              the women in her community to take part in her new business venture, Ms. Nanyonga 
              found that she could not only raise her own standard of living, but economically 
              empower the marginalized and vulnerable women in her community.
            </p>
            <p>
              Given the critical role that women and youth play in stimulating economic growth and 
              improving social welfare, promoting women enterprise development and improving access 
              to capital resources has never been more important. Since the formation of KIWOI, 
              women and youth with physical disabilities, those affected by HIV/AIDS and persons 
              displaced by violence and/or regional conflict have benefited from gaining training 
              and employment at the organisation.
            </p>
            <p>
              In addition to promoting economic literacy by providing training and education on 
              entrepreneurial management and business development, KIWOI works to promote 
              environmental justice through recycling and responsible waste management. According 
              to National Environment Management Authority (NEMA), nearly 600 tons of plastic are 
              disposed off in Kampala every day, the vast majority of which ends up littering city 
              streets and clogging vital sewage systems. With 51% of the city’s garbage left 
              uncollected, poor waste management creates serious challenges to public health and 
              safety in Uganda, which KIWOI aims to address. By repurposing more than 4,000 plastic 
              straws in the production of one straw product, KIWOI is not only turning waste into 
              wealth for the poor, but doing so in a way that will have a social impact on future 
              generations to come.
            </p>
            <p>
              Due to their availability and especially their durability when woven, plastic 
              drinking straws form the basis of KIWOI’s business model. No harsh chemicals or dyes 
              are used in the production of the company’s straw products, making them truly 
              environmentally-friendly in both form and function. Compared to plastic polythene 
              bags, the straw products produced by KIWOI are reusable, reducing the demand for 
              virgin resources. Not only does recycling cut down on material extraction, but it also 
              reduces energy wastes involved in the production of new products. By maximizing 
              recycling and minimizing waste, KIWOI is reducing the amount of greenhouse gas 
              emissions that are released into the environment as a result of human activity of 
              burning plastic straws. By using post-consumer recycled content, KIWOI is further 
              reducing global warming pollution from land filling and the incineration of waste, 
              which pose serious challenges for Uganda. 
            </p>
            
          </div>
        </div>
      </article>
    </section>
    <section>
      <article class="mb-5">
        <div class="container">
          <h2 style="font-weight: 600; font-size: 2em">Our Vision</h2>
          <p style="font-weight: 600; font-size: 1.2em">
            Empowering marginalized women and youth for self sustainability while conserving the environment
          </p>
        </div>
      </article>
    </section>
    <section>
      <article class="mb-5 objectives_section">
        <div class="container">
          <h2 style="font-weight: 600; font-size: 2em">Our Objectives</h2>
          <ul>
            <li>
              <span style="font-weight: 600;">Objective 1 - </span>
              <span>
                To empower marginalized women and youths to create self-sustainability
              </span>
            </li>
            <li>
              <span style="font-weight: 600;">Objective 2 - </span>
              <span>
                To sensitise women and youth on their rights on health, environment management, modern farming and HIV prevention
              </span>
            </li>
            <li>
              <span style="font-weight: 600;">Objective 3 - </span>
              <span>
                To train women in child nutrition, health and Education
              </span>
            </li>
            <li>
              <span style="font-weight: 600;">Objective 4 - </span>
              <span>
                Turning a burden into wealth while protecting the environment.
              </span>
            </li>
          </ul>
        </div>
      </article>
    </section>
  </div>
</template>

<script>

export default {

  data() {
    return {
      table_drawer: false,
      direction: "rtl",

      rooms: [
        {
          id: "1",
          room_code: "Kookaburra",
          title: "Kookaburra Cottage",
          room_price: 250,
          description:
            "Ideal for Families or long staying guests, the Kookaburra Cottage features three bed rooms en-suite, a cosy lounge, separate dining area and a kitchen.",
          images: [
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
          ],
        },
        {
          id: "2",
          room_code: "Deluxe",
          title: "Deluxe Room",
          room_price: 60,
          description:
            "Spacious Deluxe Rooms are elegantly appointed and feature views of the hotel’s water gardens from both the room and private balcony. Furnished with one king or two twin beds",
          images: [
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
          ],
        },
        {
          id: "3",
          title: "Executive Room",
          room_code: "Executive",
          room_price: 80,
          description:
            "Executive Rooms offer large king size beds and garden views from the private balconies. Ideal for business stays, this room category features an expansive workspace and a relaxation area with a comfortable couch and coffee table.",
          images: [
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
            require("../assets/images/products/mat.jpg"),
          ],
        },
      ],

    };
  },

  methods: {
    handleClose(done) {
      done();
    },

    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },

    accommodationDetail(roomId) {
      this.$router.push({ path: `accommodation-detail/${roomId}` });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.locus_word_style {
    font-size: 0.5em; font-weight: 300;
    word-spacing: 8px;
    letter-spacing: 5px;
  }
.dining_introduction {
  width: 80%;
}

.dining_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.dining_grid > div .dining_grid_image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.goBtn {
  color: white;
  font-weight: 600;
  transition: 0.5s;
}

.dining_grid > div:hover .goBtn {
  margin-left: 20px;
  transition: 0.5s;
}

  .btn-brown {
    background-color: #d96b12;
    color: white;
    width: 200px;
  }
  .btn-view-more {
    background-color: white;
    border: 1px solid #d96b12;
    width: 250px;
  }

.objectives_section ul{
  list-style: none;
  padding: 0px;
}

.objectives_section ul li{
  font-size: 1.2em;
  padding-top: 30px;
  padding-bottom: 10px;
  /* background-color: white; */
  border-bottom: 1px solid white;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
}
</style>
